<template>
  <v-card flat>
    <ApiError :errors="errors"></ApiError>
    <v-toolbar flat>
      <v-toolbar-title>Promotion Invitations</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="fetchData">
        <v-text-field
          class="mr-2"
          v-model="search"
          label="Filter"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClearSearch"
        ></v-text-field>
      </v-form>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="mr-2" icon v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  :items="promotionInvitationStatus"
                  v-model="selectedPromotionInvitationStatus"
                  item_value="name"
                  :item-text="item => `${item.name}`"
                  :label="$i18n.translate('Invitation') + ' ' + $i18n.translate('Status')"
                  clearable
                  @input="fetchData"
                  return-object
                ></v-select>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn v-if="isRequestor" fab small @click="onAdd()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :server-items-length="total"
        :options.sync="options"
      >
        <template v-slot:item.promotionInvitationKey="{ item }">
          <router-link :to="{ name: 'promotionInvitation', params: { id: item.id } }">
            {{ item.promotionInvitationKey }}
          </router-link>
        </template>

        <template v-slot:item.participantDetail="{ item }">
          {{ item.participant.fullName }}
          <br />
          <v-icon>mdi-email-outline </v-icon>
          {{ item.participant.email1 }}
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip :color="statusColor(item.status.name)" class="ma-2" text-color="white">{{ item.status.name }}</v-chip>
        </template>

        <template v-slot:item.createdDate="{ item }">
          {{ item.createdDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="showDeleteBtn">
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn v-on="{ ...onTooltip }" class="mr-2" icon @click="onDelete(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Delete") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-dialog v-if="deleteDialog" v-model="deleteDialog" max-width="600px">
        <v-card>
          <v-card-title class="headline">Are you sure you want to delete?</v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="onCancel()">{{ $i18n.translate("Cancel") }}</v-btn>
            <v-btn color="primary" @click="deleteInvitation()" :loading="loading">{{ $i18n.translate("Agree") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="newInvitationDialog" max-width="600px" persistent>
        <PromotionInvitationSubmissionForm
          v-if="newInvitationDialog"
          v-model="promotionInvitationSubmissionForm"
          @onCancel="onCancel()"
          @onSubmit="onSubmit()"
          :loading="loading"
        />
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import PromotionInvitationSubmissionForm from "../forms/PromotionInvitationSubmissionForm.vue";
import ApiError from "../display/ApiError.vue";
import { mapGetters } from "vuex";
import ApiService from "../../services/api.service";

export default {
  components: { ApiError, PromotionInvitationSubmissionForm },
  name: "PromotionInvitationTable",

  data: () => ({
    errors: {},
    loading: false,
    valid: false,
    loaded: false,

    headers: [
      { text: "Key", value: "promotionInvitationKey" },
      { text: "Promotion", value: "promotion.name" },
      { text: "Participant", value: "participantDetail" },
      { text: "Requester", value: "requesterParticipant.user.fullName" },
      { value: "promotion.promotionInvitationApprovalsNeeded", text: "Approvals Needed" },
      {
        value: "status",
        text: "Status",
        sortable: true,
        align: "center"
      },
      {
        value: "createdDate",
        text: "Created",
        sortable: true
      },
      { text: "Actions", value: "actions", sortable: false }
    ],
    items: [],

    creating: false,
    newParticipant: false,
    newInvitationDialog: false,
    promotionInvitationSubmissionForm: {},

    deleteDialog: false,

    editedIndex: -1,
    editedItem: {
      limitSubmissions: 1,
      participant: {
        user: {}
      }
    },
    defaultItem: {
      participant: {
        user: {}
      },
      limitSubmissions: 0
    },

    selectedParticipantToInvite: {},

    rules: {
      firstName: [v => !!v || "First Name is required."],
      lastName: [v => !!v || "Last Name is required."],
      email: [v => !!v || "E-mail is required"],
      limitSubmissions: [v => !(v && v.length > 0) || v >= 1 || "Limit Submission must be a positive number"]
    },

    search: "",
    selectedPromotionInvitationStatus: undefined,
    promotionInvitationStatus: [],

    total: 0,
    options: {
      itemsPerPage: 10,
      sortBy: ["updatedDate"],
      sortDesc: [true]
    }
  }),

  created() {
    this.fetchData();
  },

  watch: {
    options: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  },

  methods: {
    onClearSearch() {
      this.search = "";
      this.fetchData();
    },

    onAdd() {
      this.newInvitationDialog = true;
    },

    fetchData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let postForm = {};

      switch (this.selectedParticipant.participantType.participantTypeKey) {
        case "1000":
          postForm.requesterParticipant = { id: this.selectedParticipant.id };
          break;
        case "1100":
          postForm.participantApprover = { id: this.selectedParticipant.id };
          break;
      }

      if (this.search && this.search.length > 0) {
        postForm.keyword = this.search;
      }
      if (this.selectedPromotionInvitationStatus) {
        postForm.status = this.selectedPromotionInvitationStatus;
      }

      ApiService.post(
        "/api/promotionInvitations/search?size=" +
          itemsPerPage +
          "&page=" +
          (page - 1) +
          (sortBy && sortBy.length > 0
            ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
            : ""),
        postForm
      )
        .then(({ data }) => {
          this.items = data.content;
          this.total = data.totalElements;

          return this.fetchPromotionInvitationStatus();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchPromotionInvitationStatus() {
      return this.$api.get("/api/types/promotionInvitationStatusTypes").then(({ data }) => {
        this.promotionInvitationStatus = data;
      });
    },

    statusColor(status) {
      switch (status) {
        case "REJECTED":
          return "red";
        case "APPROVED":
          return "green";
        case "PROCESSING":
          return "primary";
      }
    },

    onInviteToNewParticipant() {
      this.newParticipant = true;
    },

    onDelete(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.deleteDialog = true;
    },

    deleteInvitation() {
      this.errors = {};
      this.loading = true;
      ApiService.delete("/api/promotionInvitations/" + this.editedItem.id)
        .then(() => {
          this.fetchData();
          this.onCancel();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onCancel() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.errors = {};

      this.newInvitationDialog = false;
      this.deleteDialog = false;
    },

    onSubmit() {
      this.loading = true;
      this.errors = {};

      let postForm = {};
      if (
        this.promotionInvitationSubmissionForm &&
        this.promotionInvitationSubmissionForm.selectedParticipant &&
        this.promotionInvitationSubmissionForm.selectedParticipant.id
      ) {
        postForm.participant = { id: this.promotionInvitationSubmissionForm.selectedParticipant.id };
      } else {
        postForm.participant = this.promotionInvitationSubmissionForm.selectedParticipant;
      }
      postForm.limitSubmissions = this.promotionInvitationSubmissionForm.limitSubmissions;
      postForm.effectiveDate = this.promotionInvitationSubmissionForm.effectiveDate;
      postForm.expirationDate = this.promotionInvitationSubmissionForm.expirationDate;
      postForm.ccEmail = this.promotionInvitationSubmissionForm.ccEmail;
      ApiService.post(
        "/api/promotionInvitations/" + this.promotionInvitationSubmissionForm.selectedPromotion.id + "/submit",
        postForm
      )
        .then(() => {
          this.fetchData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.newInvitationDialog = false;
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"]),

    showDeleteBtn() {
      return this.isRequestor;
    },

    isRequestor() {
      return ["100", "1000"].includes(this.selectedParticipant.participantType.participantTypeKey);
    }
  }
};
</script>
