<template>
  <v-form v-model="valid" @submit.stop.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ $i18n.translate("New") }} {{ $i18n.translate("Promotion") }} {{ $i18n.translate("Invitation") }}
      </v-card-title>
      <v-card-text>
        <ApiError :errors="errors" />
        <v-form v-model="valid" @submit.stop.prevent="onSubmit" />
        <v-row>
          <v-col>
            <v-combobox
              v-model="form.selectedPromotion"
              :items="eligiblePromotions"
              label="Eligible Promotions"
              :item-text="item => `${item.promotionKey} - ${item.name}`"
              clearable
              return-object
              :rules="rules.promotion"
              :error-messages="$error.getValidationError(errors, 'promotion')"
              class="required"
              :loading="isLoadingPromotion"
              :search-input.sync="searchInput"
            />
          </v-col>
        </v-row>
        <v-row v-if="!enrollNewParticipant">
          <v-col cols="10">
            <ParticipantField
              dense
              v-model="form.selectedParticipant"
              :preventIds="[selectedParticipant.id]"
              :label="$i18n.translate('Participant to invite')"
              :rules="rules.participant"
              :error-messages="$error.getValidationError(errors, 'participant')"
              class="required"
            />
          </v-col>
          <v-col cols="2">
            <v-btn color="primary" @click="onInviteToNewParticipant()" :disabled="!form || !form.selectedPromotion">
              <span> {{ $i18n.translate("New") }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-container v-if="enrollNewParticipant">
          <v-row>
            <v-col cols="6">
              <v-text-field
                name="firstName"
                :label="$i18n.translate('First Name')"
                id="firstName"
                v-model="form.selectedParticipant.user.firstName"
                :rules="rules.firstName"
                :error-messages="$error.getValidationError(errors, 'firstName')"
                @input="$error.clearValidationError(errors, 'firstName')"
                class="required"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                name="lastName"
                :label="$i18n.translate('Last Name')"
                id="lastName"
                v-model="form.selectedParticipant.user.lastName"
                :rules="rules.lastName"
                :error-messages="$error.getValidationError(errors, 'lastName')"
                @input="$error.clearValidationError(errors, 'lastName')"
                class="required"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <EmailField
                id="email"
                name="email"
                v-model="form.selectedParticipant.user.email"
                label="Email Address"
                :rules="rules.email"
                :error-messages="$error.getValidationError(errors, 'email')"
                @input="$error.clearValidationError(errors, 'email')"
                class="required"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-row>
          <v-col cols="12">
            <EmailField name="ccEmail" id="ccEmail" label="CC Email" v-model="form.ccEmail" :notRequired="true" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              type="number"
              label="Limit Submission"
              name="limitSubmissions"
              id="limitSubmissions"
              v-model="form.limitSubmissions"
              :rules="rules.limitSubmissions"
              :error-messages="$error.getValidationError(errors, 'limitSubmissions')"
              @input="$error.clearValidationError(errors, 'limitSubmissions')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <DateTimePickerField
              v-model="form.effectiveDate"
              :label="$i18n.translate('Effective Date')"
              defaultTime="00:00"
            />
          </v-col>
          <v-col cols="6">
            <DateTimePickerField
              v-model="form.expirationDate"
              :label="$i18n.translate('Expiration Date')"
              defaultTime="23:59"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="onCancel()" :loading="loading">{{ $i18n.translate("Cancel") }}</v-btn>
        <v-btn color="primary" :disabled="!valid" @click="onSubmit()" :loading="loading">
          {{ $i18n.translate("Submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import DateTimePickerField from "@/gapp-components/components/fields/DateTimePickerField.vue";
import EmailField from "@/gapp-components/components/fields/EmailField.vue";
import ParticipantField from "@/gapp-components/components/fields/ParticipantField.vue";
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex";
import ApiService from "@/gapp-components/services/api.service";
import moment from "moment-timezone";
import { debounce } from "lodash";

export default {
  components: { ApiError, ParticipantField, EmailField, DateTimePickerField },
  name: "PromotionInvitationSubmissionForm",
  props: {
    value: Object,
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: true,
      isLoadingPromotion: false,
      enrollNewParticipant: false,
      searchInput: null,
      form: {},
      rules: {
        firstName: [v => !!v || "First Name is required"],
        lastName: [v => !!v || "Last Name is required"],
        email: [v => !!v || "E-mail is required"],
        limitSubmissions: [v => !(v && v.length > 0) || v >= 1 || "Limit Submission must be a positive number"],
        promotion: [v => !!v || "Promotion is required"],
        participant: [v => !!v || "Participant is required"]
      },
      errors: {},
      eligiblePromotions: [],
      promotionsFetched: 5
    };
  },
  watch: {
    searchInput: {
      handler: function(newVal) {
        this.debouncedFetchPromotions(newVal);
      },
      immediate: true
    }
  },
  mounted() {
    this.debouncedFetchPromotions = debounce(this.fetchPromotions, 300);
    this.fetchPromotions();
  },
  computed: {
    ...mapGetters(["selectedParticipant"]),
    isRequestor() {
      return this.selectedParticipant.participantType.participantTypeKey === "1000";
    }
  },
  methods: {
    onInviteToNewParticipant() {
      this.enrollNewParticipant = true;
      this.form.selectedParticipant = {
        user: {},
        address: {
          country: { name: this.form.selectedPromotion.country.name }
        }
      };
    },
    onCancel() {
      this.form.selectedParticipant = null;
      this.$emit("onCancel");
    },
    onSubmit() {
      this.$emit("input", this.form);
      this.$emit("onSubmit");
    },
    fetchPromotions(search = "") {
      this.isLoadingPromotion = true;
      const postForm = {
        invitationOnly: true,
        keyword: search,
        publishDateBefore: moment
          .tz([moment().year(), moment().month(), moment().date(), 0, 0, 0], "America/New_York")
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      };
      if (this.isRequestor) {
        postForm.promotionInvitationParticipantRequester = { id: this.selectedParticipant.id };
      }
      ApiService.post(`/api/promotions/search?size=${this.promotionsFetched}`, postForm)
        .then(({ data }) => {
          this.eligiblePromotions = data.content.map(result => {
            return result.promotion;
          });
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.isLoadingPromotion = false;
        });
    }
  }
};
</script>
